import React from "react";
import styles from "../../styles/Main.module.css";
import { ArrowLeft } from "../../assets/Svg";

export default function SectionDisplay({ onClick, title, subtitle }) {
  return (
    <div className={styles.sectionDisplay} onClick={onClick}>
      <div className={styles.sectionDisplayInset}>
        <div className={styles.sectionDisplayContent}>
          <div>
            <div className={styles.sectionDisplayTitle}>{title}</div>
            {subtitle && (
              <div className={styles.sectionDisplaySubtitle}>{subtitle}</div>
            )}
          </div>
          <div className={styles.sectionDisplayArrow}>
            <ArrowLeft />
          </div>
        </div>
      </div>
    </div>
  );
}
