import React, { useState } from "react";
import styles from "../../styles/Main.module.css";
import Button from "../Button";
import rightArrow from "../../assets/images/rightArrow.svg";
import { ArrowLeft } from "../../assets/Svg";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "react-responsive";
import { format as formatTimeago } from "timeago.js";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { tokenSnifferAddressState } from "../../recoil/search";

export default function CoinsGrid() {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);
  const setTokenAddress = useSetRecoilState(tokenSnifferAddressState);

  const { data, isSuccess, isError, error } = useQuery({
    queryKey: ["/dashboard/home"],
  });

  const newTokens = data ? data.newCoins : [];
  const displayTokens = hidden ? newTokens?.slice(0, 5) : newTokens;

  const handleSeeMore = (address) => {
    setTokenAddress(address);
    navigate(`/token-sniffer?address=${address}`);
  };

  return (
    <div className={styles.coinsGrid}>
      <div className={styles.headersGrid}>
        <div>Coin name</div>
        {!isMobile ? (
          <>
            <div>launched at</div>
            <div>Txs Count</div>
            <div>holders count</div>
          </>
        ) : null}
        <div>price</div>
        <div></div>
      </div>

      <div className={styles.coinsGridContent}>
        {isSuccess ? (
          displayTokens?.map((token) => {
            return (
              <div className={styles.valuesGrid} key={token.address}>
                <div className={styles.name}>
                  <img
                    src={
                      "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579"
                    }
                    alt="coin1"
                  />
                  <span>{token.name}</span>
                </div>
                {!isMobile ? (
                  <>
                    <div className={styles.coinGridItem}>
                      {formatTimeago(token.createdAtTimestamp)}
                    </div>
                    <div className={styles.coinGridItem}>{token.txsCount}</div>
                    <div className={styles.coinGridItem}>
                      {token.holdersCount}
                    </div>
                  </>
                ) : null}
                <div className={styles.price}>
                  {token.price?.rate.toFixed(5)}$
                </div>
                <div
                  className={styles.seeMore}
                  onClick={() => handleSeeMore(token.address)}
                >
                  {!isMobile ? (
                    "see more"
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.959"
                      height="13.84"
                      viewBox="0 0 7.959 13.84"
                    >
                      <path
                        id="Path_637"
                        data-name="Path 637"
                        d="M7.655.3a1.04,1.04,0,0,1,0,1.47L2.509,6.92l5.146,5.146a1.039,1.039,0,1,1-1.47,1.47L.3,7.655a1.04,1.04,0,0,1,0-1.47L6.185.3a1.04,1.04,0,0,1,1.47,0Z"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                    </svg>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}

        {hidden ? <div className={styles.blur}></div> : null}

        <div
          className={`${styles.center} ${
            hidden ? styles.seeFullListButton : styles.hideBtn
          }`}
        >
          <Button
            variant="primary"
            onClick={() => {
              setHidden((prev) => !prev);
            }}
          >
            <span className={styles.seeFullList}>
              {hidden ? "see full list" : "hide full list"}
              <ArrowLeft />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
